import { WppActionButton, WppButton, WppIconWarning, WppModal } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAsyncFn } from 'react-use'

import { useDeactivateCollectionTenantApi } from 'api/collections/mutations/useDeactivateCollectionTenantApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { ProductTenant } from 'types/products/tenant'
import { Flex } from 'ui-base/flex/Flex'
import { unpackApiError } from 'utils/form'

interface Props {
  open: boolean
  activeTenantId: ProductTenant['tenantId']
  handleClose: () => void
}

export const DeactivationModal = ({ activeTenantId, open, handleClose }: Props) => {
  const { t } = useTranslation(['common', 'collections'])
  const queryClient = useQueryClient()
  const { collectionId } = useParams()
  const { showToast } = useToast()

  const { mutateAsync: deactivateTenant } = useDeactivateCollectionTenantApi()

  const [{ loading: isDeactivating }, handleClickDeactivationButton] = useAsyncFn(async () => {
    try {
      await deactivateTenant({
        tenantId: activeTenantId,
        collectionId: collectionId!,
      })

      await Promise.all([
        queryClient.invalidateQueries([ApiQueryKeys.COLLECTION_TENANT_AVAILABILITY]),
        queryClient.invalidateQueries([ApiQueryKeys.COLLECTION_TENANTS_AVAILABILITY]),
        queryClient.invalidateQueries([ApiQueryKeys.COLLECTION]),
        queryClient.invalidateQueries([ApiQueryKeys.COLLECTIONS]),
      ])

      showToast({
        message: t('collections|availability.notifications.success_message_change_access'),
        type: 'success',
      })
    } catch (apiError) {
      const { detail } = unpackApiError(apiError)
      const defaultError = t('collections|availability.notifications.failure_message_change_access')

      showToast({
        message: detail || defaultError,
        type: 'error',
      })
    }

    handleClose()
  }, [activeTenantId, queryClient])

  return (
    <WppModal open={open} disableOutsideClick data-testid="availability-deactivation-modal">
      <Flex gap={8} align="center" slot="header">
        <WppIconWarning data-testid="warning-icon" />
        {t('collections|availability.deactivation_modal.title')}
      </Flex>
      <p slot="body">{t('collections|availability.deactivation_modal.description')}</p>
      <Flex justify="flex-end" align="center" gap={8} slot="actions">
        <WppActionButton variant="secondary" onClick={handleClose} data-testid="deactivate-availability-cancel-button">
          {t('common|cancel')}
        </WppActionButton>
        <WppButton
          value="deactivate"
          onClick={handleClickDeactivationButton}
          variant="primary"
          size="s"
          loading={isDeactivating}
          data-testid="deactivate-availability-save-button"
        >
          {t('common|deactivate')}
        </WppButton>
      </Flex>
    </WppModal>
  )
}
