import { devHubApi } from 'api'
import { CollectionDTO } from 'types/collections/collection'
import { ProductTenant } from 'types/products/tenant'

interface Params {
  collectionId: CollectionDTO['id']
  tenantId: ProductTenant['tenantId']
}

export const deactivateCollectionTenantApi = ({ collectionId, tenantId }: Params) =>
  devHubApi.post(`/collections/${collectionId}/availability/${tenantId}/deactivate`)
