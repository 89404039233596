export enum AppPermissions {
  WPP_OPEN_DEVHUB_APP_ACCESS = 'WPP_OPEN_DEVHUB_APP_ACCESS',
  WPP_OPEN_DEVHUB_SELF_PRODUCTS_DELETE = 'WPP_OPEN_DEVHUB_SELF_PRODUCTS_DELETE',
  WPP_OPEN_DEVHUB_PRODUCT_MANAGE = 'WPP_OPEN_DEVHUB_PRODUCT_MANAGE',
  WPP_OPEN_DEVHUB_VERSION_MANAGE = 'WPP_OPEN_DEVHUB_VERSION_MANAGE',
  WPP_OPEN_DEVHUB_PRODUCT_VIEW = 'WPP_OPEN_DEVHUB_PRODUCT_VIEW',
  WPP_OPEN_DEVHUB_PRODUCT_DELETE = 'WPP_OPEN_DEVHUB_PRODUCT_DELETE',
  WPP_OPEN_DEVHUB_VERSION_DELETE_PUBLISHED = 'WPP_OPEN_DEVHUB_VERSION_DELETE_PUBLISHED',
  WPP_OPEN_DEVHUB_PERM_DELETE_PUBLISHED = 'WPP_OPEN_DEVHUB_PERM_DELETE_PUBLISHED',
  WPP_OPEN_DEVHUB_TENANT_AVAILABILITY_MANAGE = 'WPP_OPEN_DEVHUB_TENANT_AVAILABILITY_MANAGE',
  WPP_OPEN_DEVHUB_PRODUCT_PUBLISH = 'WPP_OPEN_DEVHUB_PRODUCT_PUBLISH',
  WPP_OPEN_DEVHUB_ALL_TENANTS_OPEN_PRODUCTS_VIEW = 'WPP_OPEN_DEVHUB_ALL_TENANTS_OPEN_PRODUCTS_VIEW',
  WPP_OPEN_DEVHUB_PRODUCT_COLLECTIONS_MANAGE = 'WPP_OPEN_DEVHUB_PRODUCT_COLLECTIONS_MANAGE',
}

export enum AccessActions {
  PRODUCT_MANAGE = 'PRODUCT_MANAGE',
  PRODUCT_VIEW = 'PRODUCT_VIEW',
  PRODUCT_PUBLISH = 'PRODUCT_PUBLISH',
  PRODUCT_DELETE = 'PRODUCT_DELETE',
  VERSION_MANAGE = 'VERSION_MANAGE',
  VERSION_DELETE_PUBLISHED = 'VERSION_DELETE_PUBLISHED',
  TENANT_AVAILABILITY_MANAGE = 'TENANT_AVAILABILITY_MANAGE',
  PERM_DELETE_PUBLISHED = 'PERM_DELETE_PUBLISHED',
  MEMBER_MANAGE = 'MEMBER_MANAGE',
}

type EnsureCorrectEnum<T extends { [K in Exclude<keyof T, number>]: K }> = true
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type EnsureCorrectPermissions = EnsureCorrectEnum<typeof AppPermissions>
