import { useOs } from '@wpp-open/react'
import { Route, Navigate, createRoutesFromElements, createBrowserRouter, RouterProvider } from 'react-router-dom'

import { routes } from 'app/routes'
import { ApiErrorTypes } from 'constants/apiErrors'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { Layout } from 'layout/Layout'
import { InternalApiErrorProvider } from 'providers/internalApiError/InternalApiErrorProvider'
import { ErrorPage } from 'ui-base/errorPage/ErrorPage'
import { RoutesManager } from 'utils/routesManager'

export const AppRoutes = () => {
  const { isPermitted } = useIsPermitted()
  const {
    osContext: { baseUrl },
  } = useOs()

  const accessibleRoutesList = createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={
          // InternalApiErrorProvider should be placed in root Route to be able to use useLocation hook inside
          <InternalApiErrorProvider>
            <Layout />
          </InternalApiErrorProvider>
        }
      >
        <Route key="myProducts" index element={<Navigate to={RoutesManager.myProducts.root.getURL()} replace />} />
        <Route key="productsV2" index element={<Navigate to={RoutesManager.productsV2.root.getURL()} replace />} />
        {routes.map(({ permissions, requireAllPermissions, Component, path }, index) => {
          const isRoutePermitted = !permissions || isPermitted(permissions, requireAllPermissions)

          return (
            <Route
              key={path || index}
              path={path}
              element={isRoutePermitted ? Component : <ErrorPage errorType={ApiErrorTypes.ACCESS_DENIED} />}
            />
          )
        })}
        <Route path="*" key="not-found" element={<ErrorPage errorType={ApiErrorTypes.NOT_FOUND} />} />
      </Route>,
    ),
    { basename: `/${baseUrl}` },
  )

  return <RouterProvider router={accessibleRoutesList} />
}
